<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
            <!-- 期间限定 -->
      <a style="display: block;" target="_blank" href="https://maidocoin.com/entrance/?lang=ko">
        <el-image :src="qjxdImg">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </a>

      <!-- 积分 -->
      <a id="stepImgId" style="display: block;" target="_blank" href="https://tripellet.com/tcapp-download-b/kr">
        <el-image :src="stepImg">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </a>
      <!-- 设施一览表 -->
      <div style="background-color: #f3d2c3;">
        <el-image :src="modelcourse">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
        </el-image>
        <el-image :src="modelcourse2">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
        <el-image :src="placeholder_title">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </div>
      <!-- 使用方法 -->
      <!-- <div class="usage_box">
        <div class="title">오사카, 고베, 교토 등 간사이 지역의 매력적인 관광지와 인기 시설을 대상으로 한 가성비 있는 티켓입니다. 유효 기간 내에 대상 시설 중에서 원하는 시설을 3곳 또는 6곳 선택하여 이용하실 수 있습니다. E-티켓에 기재된 QR 코드를 제시하시면 간편하게 입장할 수 있습니다.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">사용방법</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
            ● 사용방법 : 예약 후 메일로 1장의 PDF를 수령한 후, 이용기간 중 해당 시설에 방문하여 QR코드를 제시한 뒤 교환해 주세요. <br>
            ● 각 시설은 1회 한정으로 이용 가능하며, 동일 시설에 재입장하실 수 없습니다.<br>
            ● 이용 개시 가능일 : 구입일로부터 <span>90일간 </span><br>
            예시 : <span>7/1</span> 구입 ⇒ <span>이용 가능 기간 : 7/1~9/28</span> <br>
            ● 해당 패스는 첫 번째 시설 이용부터 <span>7일간</span> 유효합니다. <br>
            예시 : 첫 번째 시설 이용일 <span>7/1</span> ⇒ <span>유효기간 7/1~7/7</span> <br>
            ● 이용 가능 시설 : 유효 기간(7일) 내 <span>3개까지</span> 또는 <span>6개까지</span> 이용 가능 <br>
            ● 1회 주문당 여러 개의 패스를 구입하는 경우, 모든 패스의 유효 기간은 동일하므로 주의하시기 바랍니다. (<span>이용 개시부터 7일간</span>) <br>
            ● 유효 기간은 이용 개시부터 7일간이지만, 구입일로부터 <span>90일</span> 이후에는 이용하실 수 없으므로 주의하시기 바랍니다. <br>
            예시 : 구입일이 <span>7/1</span>인 경우, <span>7/1~9/28</span>에만 이용 가능합니다. <span>9/27</span>부터 이용 개시하셔도 <span>9/28</span>까지만 이용하실 수 있습니다. <br>
            ● 미사용 시설은 환불되지 않습니다. <br>
            ● 각 시설의 주의사항, 영업시간, 정기휴일 등은 다음 링크에서 확인해 주세요. 
        </div>
      </div> -->
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <div class="xiao_btn_img" v-if="item.iconImgBr">
          <a v-if="item.iconImgBr1" target="_blank" :href="item.iconVideoHref"><img :src="xiao_btn_img1" alt=""></a>
          <a v-if="item.iconImgBr2" href="#stepImgId"><img :src="xiao_btn_img2" alt=""></a>
          <a v-if="item.iconImgBr3" href="javascript:;"><img :src="xiao_btn_img3" alt=""></a>
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='주의사항'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='티켓교환장소' && !contentItem.noColor">{{cItem}}<br></span>
                    <a class="dltAbq" :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='공식 사이트' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 주의사항特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfkansai/kr/havefun_kansai_kr.png'),
      qjxdImg: require('@/assets/images/hfkansai/kr/qjxd_banner_kr.png'),
      stepImg: require('@/assets/images/hfkansai/kr/4step_kr.png'),
      modelcourse: require('@/assets/images/hfkansai/kr/havefun_modelcourse_1_kr.jpg'),
      modelcourse2: require('@/assets/images/hfkansai/kr/havefun_modelcourse_2_kr.jpg'),
      placeholder_title: require('@/assets/images/hfkansai/kr/placeholder_title_kr.png'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      xiao_btn_img1: require('@/assets/images/hfkansai/kr/play_KR.png'),
      xiao_btn_img2: require('@/assets/images/hfkansai/jifen.png'),
      xiao_btn_img3: require('@/assets/images/hfkansai/kr/qjxd2_KR.png'),
      dataList: [
        // {
        //   id: 1,
        //   img: require('@/assets/images/hfkansai/en/グループ 218.png'),
        //   title: ['하루카스300 전망대'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '티켓교환장소',
        //       content: [
        //         { cid: 1, text: ['오사카부 오사카시 아베노구 아베노스지 1-1-43'] },
        //         { cid: 2, text: ['(HARUKAS30016층 티켓카운터에)'] }
        //       ]
        //     },
        //     {
        //       msgId: 2,
        //       title: '공식 사이트',
        //       content: [{ cid: 1, text: ['https://www.abenoharukas-300.jp/kr/observatory/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: '주의사항',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['한번 퇴장하시면 재입장하실 수 없으므로 주의해 주세요.'] },
        //         { cid: 2, text: ['이용당일 16층 티켓카운터에 e티켓 제시 후 입장해 주세요.'] }
        //       ]
        //     }]
        // },
        {
          id: 2,
          img: require('@/assets/images/hfkansai/kintetsu_en2.jpg'),
          title: ['긴테쓰백화점 오리지널 세트 교환권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [
                { cid: 1, text: ['오사카부 오사카시 아베노구 아베노스지 1-1-43'] },
                { cid: 2, text: ['윙관 3.5층 Foreign Customer’s Salon'] }
              ]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://abenoharukas.d-kintetsu.co.jp/special/foreign/index-kr.html'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['긴테쓰백화점 오리지널 세트 교환권을 이용할 수 있습니다. ','(긴테쓰백화점 오리지널 이마바리 타월 & 긴테쓰백화점 오리지널 클리어 파일 1장 세트)'] },
                { cid: 2, text: ['세트 상품은 반품 및 교환하실 수 없습니다.'] },
                { cid: 3, text: ['긴테쓰백화점 아베노하루카스 긴테쓰본점에서만 이용할 수 있습니다.'] },
                { cid: 4, text: ['사진은 이미지입니다. 사진과 실제 상품이 일부 다른 경우가 있습니다.'] }
              ]
            }]
        },
        {
          id: 3,
          img: require('@/assets/images/hfkansai/en/グループ 217.png'),
          title: ['오사카 원더루프버스'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['오사카부 오사카시 츄오구 도톤보리1-1-6 B1F', '(Dotonbori Riverside Grill Wonder)'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://wonderloop.jp/wonder-loop/'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['주행 중에는 안전벨트를 착용해 주세요.', '또한 위험하므로  좌석 이동을 삼가 주세요.'] },
                { cid: 2, text: ['이벤트 등(교통규제 및 유도)이 있는 경우에는 운휴 또는 ', '노선이 변경될 수 있습니다.'] }
              ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfkansai/en/グループ 216.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/oXH-_2qjNlU',
          title: ['오사카 원더 크루즈'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['오사카부 오사카시 츄오구 도톤보리1-1-6 B1F', '(Dotonbori Riverside Grill Wonder)'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://wondercruise.jp/doton/'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['운행스케줄은 공식사이트에서 확인해 주세요.'] },
                { cid: 2, text: ['천재지변으로 인해 운행이 중지될 수 있습니다.'] },
                { cid: 3, text: ['만석인 경우에는 승선이 거절될 수 있습니다.'] },
                { cid: 4, text: ['매주 월~수요일 은 예약자 한정으로 운행됩니다.'] }
              ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfkansai/en/グループ 223.png'),
          title: ['오사카 구루메 워크(2장)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [
                { cid: 1, text: ['●오사카부 오사카시 츄오구 도톤보리1-1-6 B1F', '(Dotonbori Riverside Grill Wonder)'] },
                ]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['http://gourmet-walk.com/'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: [' 간사이 조이 패스를 교환장소에 제시하시면, 오사카 구루메 워크 티켓 2장을 받을 수 있습니다.'] },
                { cid: 2, text: ['티켓은 직접 떼지 마시고, 반드시 붙어있는 상태의 티켓을. 가게에 건네주세요.'] },
                { cid: 3, text: ['구루메 워크 메뉴는 변경하여 제공되지 않습니다.'] },
                { cid: 4, text: ['잔여 티켓은 현금화할 수 없으므로 양해 부탁드립니다.'] },
                { cid: 5, text: ['교환장소의 영업시간을 사전에 공식사이트에서 확인해 주세요.'] }
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfkansai/kr/298_kr.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/TGv70R5L37k',
          title: ['298 야키니쿠 1000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '●298 난바점(일시영업중지)',
                '오사카부 오사카시 츄오구 센니치마에 1-7-9 센니치마에 스바루자 히가시 아지비루 미나미 4F',
                '●298 신사이바시점',
                '오사카부 오사카시 츄오구 신사이바시스지 1-2-8',
                '●298 우메다3호점',
                '오사카부 오사카시 키타구 도우야마쵸 5-8 ',
                '(이즈미노 히로바로 올라가는 북쪽)',
                '●298 산노미야점 <span style="color:#FBB03B;">(고베)</span>',
                '고베시 츄오구 키타나가사도오리 1-4-2 아지비루 산노미야 B1',
                '●298 후쿠시마점',
                '오사카부 오사카시 후쿠시마구 후쿠시마 6-1-39'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['298 난바점 '],noColor:true },
                { cid: 2, text: ['http://www.ajibil.com/shop/298N.html'] },
                { cid: 3, text: ['298 신사이바시점'],noColor:true },
                { cid: 4, text: ['https://298shinsaibashi.owst.jp/ko/'] },
                { cid: 5, text: ['298 우메다3호점'],noColor:true },
                { cid: 6, text: ['https://ajibiru-kashin-umeda.owst.jp/ko/'] },
                { cid: 7, text: [`298 산노미야점  <span style="color:#FBB03B;">(고베)</span>`],noColor:true },
                { cid: 8, text: ['http://www.ajibil.com/shop/298S.html'] },
                { cid: 9, text: [`298 후쿠시마점`],noColor:true },
                { cid: 10, text: ['https://r.gnavi.co.jp/jm0fdd5d0000/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000엔 쿠폰을 이용할 수 있습니다.'] },
                { cid: 2, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 3, text: ['298 산노미야점은 고베에 있습니다.'] },
                { cid: 4, text: ['다음 5개 매장 중 한 곳을 선택해 주세요.','298 난바점, 298 신사이바시점, 298 우메다3호점, 298 산노미야점, 298 후쿠시마점'] },
                { cid: 5, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다.사전에 양해 부탁드립니다.'] }
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hfkansai/nonotori_EN.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/wg6_QwDLejA',
          title: ['노노토리 1000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '●野乃鳥なんば堂',
                '오사카시 쥬오구 난바 3-7-19 GEMS 난바 8F',
                '●KOBE YAKITORI STAND 野乃鳥<span style="color:#FBB03B;">(고베)</span>',
                '고베시 쥬오구 기타나가사도리 1-1-1 EKIZO 고베산노미야',
                '●野乃鳥 梅味堂',
                '오사카시 기타구 나카사기니시 3-3-15 JR고가시타34'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['野乃鳥なんば堂'],noColor:true },
                { cid: 2, text: ['https://nonotory.jp/stores/nambado/'] },
                { cid: 3, text: ['KOBE YAKITORI STAND 野乃鳥<span style="color:#FBB03B;">(고베)</span>'],noColor:true },
                { cid: 4, text: ['https://nonotory.jp/stores/kobe-yakitori-stand/'] },
                { cid: 5, text: ['野乃鳥 梅味堂'],noColor:true },
                { cid: 6, text: ['https://nonotory.jp/stores/umemido/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000엔 쿠폰을 이용할 수 있습니다.'] },
                { cid: 2, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 3, text: ['KOBE YAKITORI STAND 노노토리은 고베에 있습니다.'] },
                { cid: 4, text: ['다음 3개 매장 중 한 곳을 선택해 주세요.','노노토리 난바도、KOBE YAKITORI STAND 노노토리、노노토리 우메미도.'] },
                { cid: 5, text: ['가게 이용 시, 자릿세가 추가됩니다.'] },
                { cid: 6, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                { cid: 7, text: ['계산 시 Travel Contents 앱 화면을 보여주신 후, QR코드를 스캔하면 결제 금액의 10%를 포인트로 받을 수 있습니다.','<br> 다운로드 : <a style="color: rgb(33, 157, 204);border-bottom: 1px solid;" href="http://onelink.to/xyx76x">http://onelink.to/xyx76x</a>'] }
                ]
            }
          ]
        },
        {
          id: 46,
          img: require('@/assets/images/hfkansai/en/heniuunou_EN.png'),
          iconImgBr: true,
          // iconImgBr1: true,
          iconImgBr2: true,
          iconImgBr3: true,
          title: ['Wagyu Yakiniku Unou 1,000엔 할인 쿠폰 ','<span>(기간한정:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [
                { cid: 1, text: ['Bekkan:大阪府大阪市西成区玉出中2-15-22 ']},
                { cid: 2, text: ['Miyakojima Hondori: 大阪府大阪市都島区都島本通3-27-7 '] },
                { cid: 3, text: ['Honten:大阪府大阪市福島区福島2-7-7 '] },
                ]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['Bekkan:공식웹사이트'],noColor:true},
                { cid: 4, text: ['Miyakojima Hondori'],noColor:true},
                { cid: 5, text: ['https://www.hotpepper.jp/strJ000716500/']},
                { cid: 6, text: ['https://instagram.com/unou_gram?igshid=YzAwZjE1ZTI0Zg==']},
                { cid: 6, text: ['Honten:'],noColor:true},
                { cid: 7, text: ['https://unoufukushima.owst.jp/']},
                { cid: 8, text: ['https://www.instagram.com/unou_7319/?hl=ja']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 3, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                { cid: 4, text: ['점포 한 곳당 한 번만 이용 가능합니다.','<br>예: Bekkan을 이용하셨다면, 더 이상 Bekkan은 이용하실 수 없고 Miyakojima Hondori는 이용 가능합니다.'] },
                { cid: 5, text: ['가게가 혼잡한 경우에는 이용 시간을 90분으로 제한합니다.'] },
                ]
            }
          ]
        },
        {
          id: 47,
          img: require('@/assets/images/hfkansai/en/Nanjamonja_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['Nanja Monja 1,000엔 할인 쿠폰','<span>(기간한정:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [
                { cid: 1, text: ['大阪府大阪市中央区東心斎橋1-13-19 NTビル2F'],noColor:true }
                ]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://gurunavi.com/ko/k342800/rst/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 할인 쿠폰을 이용하여 오사카 명물 오코노미야키를 드실 수 있습니다.'] },
                { cid: 2, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 3, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 4, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 48,
          img: require('@/assets/images/hfkansai/en/95jiaoshi_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          iconImgBr2: true,
          iconImgBr3: true,
          title: ['오사카 산신 클래스 1시간 체험 (사전 예약 필수)','<span>(기간한정:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [
                { cid: 1, text: ['大阪府大阪市生野区巽中4-4-5'],noColor:true }
                ]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://500en-sanshin-school.jimdosite.com/']},
                { cid: 2, text: ['예약 사이트:'],noColor:true },
                { cid: 3, text: ['https://airrsv.net/sansin/calendar']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['상품 내용: 1시간 체험 요금'] },
                { cid: 3, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 49,
          img: require('@/assets/images/hfkansai/en/96jas_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['종교 법인 큐안지 (수행 체험 3종)(사전 예약 필수)','<span>(기간한정:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [
                { cid: 1, text: ['大阪府池田市伏尾町697'],noColor:true }
                ]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://kyuanji.jp/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['상품 내용: 명상, 경문 필사 체험, 부처님 그리기 체험 중 1가지 선택 가능하며 입장료 300엔이 포함되어 있습니다.'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 50,
          img: require('@/assets/images/hfkansai/en/97rwgj_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['염색소 KIKYU 염색 체험 1,000엔 할인 쿠폰 (사전 예약 필수)','<span>(기간한정:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [
                { cid: 1, text: ['大阪府池田市栄町6-17'],noColor:true }
                ]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://someyakikyu.com/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 할인 쿠폰을 이용하실 수 있습니다.'] },
                { cid: 2, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 3, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 51,
          img: require('@/assets/images/hfkansai/en/98bbdc_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['일본 다테도 협회 1,000엔 할인 쿠폰 (사전 예약 필수)','<span>(기간한정:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [
                { cid: 1, text: ['大阪府大阪市西区北堀江1丁目1-27 (3F)'],noColor:true }
                ]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://nihontatedokyokai.com/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 할인 쿠폰을 이용하실 수 있습니다.'] },
                { cid: 2, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 3, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 4, text: ['사전 예약 필수입니다.'] },
                ]
            }
          ]
        },
        {
          id: 52,
          img: require('@/assets/images/hfkansai/en/Kawaii_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['카와이 오사카 KIMONO SHOP 1,000엔 할인 쿠폰 (기모노 구입/체험) ','<span>(기간한정:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [
                { cid: 1, text: ['大阪府大阪市中央区宗右衛門町1-9'],noColor:true }
                ]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://kawaii-osaka.jp/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 할인 쿠폰을 이용하실 수 있습니다. (기모노 구입/체험)'] },
                { cid: 2, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 3, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 4, text: ['현장에서 기모노 대여 시, 예약 상황 등으로 인해 원활하게 대응할 수 없는 경우가 있습니다. 공식 홈페이지에서 인터넷 예약하시는 것을 추천드립니다.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 17,
          img: require('@/assets/images/hfkansai/en/SantaMaria_en.png'),
          title: ['산타마리아 데이 크루즈 승선권','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒552-0022 오사카부 오사카시 미나토구 가이간도리 1-1-10',],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://suijo-bus.osaka/language/santamaria/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['운행 스케줄은 공식 사이트에서 확인해 주세요.'] },
                { cid: 2, text: ['강풍 및 비 등의 기상 상황으로 인해 운행이 중지될 수 있습니다.'] },
                { cid: 3, text: ['만석인 경우에는 승선이 거절될 수 있습니다.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 18,
          img: require('@/assets/images/hfkansai/en/Paragliding_en.png'),
          title: ['패러글라이딩 솔로 체험 1000엔 쿠폰','<span>(2023년 7월~8월 이용 불가)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오사카부 오사카시 고노하나구 홋코료쿠치 2-1-44',
                '(마이시마 어반 아웃도어 베이스)',],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.west-east.jp/paraglider/index.html']},
                { cid: 2, text: ['예약사이트(일본어만 지원) '],noColor:true},
                { cid: 3, text: ['https://osakamachipara.com/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['완전 예약제로 운영하고 있습니다. 이용하실 때에는 사전에 예약해 주세요. 사전 예약을 하지 않으셨을 경우, 휴업일인 경우도 있습니다. 확인하신 후 이용해 주세요'] },
                { cid: 2, text: ['완전 예약제. 홈페이지 예약 (2월 20일 이후 오픈)'] },
                { cid: 3, text: ['준비물로 활동하기 편한 복장, 운동화가 필요합니다.'] },
                { cid: 4, text: ['그 외 사항은 당일 타임 스케줄 또는 체험신청서를 참고해 주세요.'] },
                { cid: 5, text: ['이용 제한 (나이 만 3세 이상, 신장 190cm 이하, 체중 80kg 이하)'] },
                ]
            }
          ]
        },
        // 
        {
          id: 19,
          img: require('@/assets/images/hfkansai/en/SUPExperience_en.png'),
          title: ['수상 산책 SUP 체험 1000엔 쿠폰','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒554-0042 오사카부 오사카시 고노하나구 홋코료쿠치 1-2-25'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.west-east.jp/mishima-citysup.html']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['원형 SUP/BIG SUP는 체험자가 중학생 이하만으로 구성된 경우, 체험이 불가합니다.'] },
                { cid: 2, text: ['자전거 SUP에는 신장 제한이 있습니다.'] },
                { cid: 3, text: ['음주자/임산부는 체험이 불가합니다.'] },
                { cid: 4, text: ['날씨에 따라 중지될 수 있습니다.'] },
                { cid: 5, text: ['젖어도 상관없는 복장으로 참가해 주세요.','<br>(물보라 혹은 서핑보드에서 떨어질 경우 젖을 가능성이 있습니다.)'] },
                ]
            }
          ]
        },
        // 
        {
          id: 20,
          img: require('@/assets/images/hfkansai/en/KeihanRailwa_en.png'),
          title: ['게이한 전철 교토-오사카 관광 1일권','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [
                { cid: 1, text: ['게이한 전철 (요도야바시역)'],noColor:true },
                { cid: 2, text: ['오사카시 주오구 기타하마 3-1-25 (프리미엄카 창구)','<br>'],noColor:true },
                { cid: 3, text: ['게이한 전철 (기타하마역)'],noColor:true },
                { cid: 4, text: ['오사카부 오사카시 주오구 기타하마 1-8-16 (프리미엄카 창구소)','<br>'],noColor:true },
                { cid: 5, text: ['게이한 전철 (덴마바시역)'],noColor:true },
                { cid: 6, text: ['오사카부 오사카시 주오구 다니마치 1-1 (역내 창구)','<br>'],noColor:true },
                { cid: 7, text: ['게이한 전철 (교바시역)'],noColor:true },
                { cid: 8, text: ['오사카부 오사카시 미야코지마구 히가시노다마치 2-1-38 (프리미엄카 창구)','<br>'],noColor:true },
                { cid: 9, text: ['게이한 전철 (산조역)'],noColor:true },
                { cid: 10, text: ['교토부 교토시 히가시야마구 오하시초 산조 오하시 히가시즈메 (프리미엄카 창구)'],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.keihan.co.jp/travel/kr/trains/passes-for-visitors-to-japan/kyoto-osaka.html']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['승차 전 실물 티켓으로 교환해야 합니다.'] },
                { cid: 2, text: ['교환한 티켓의 유효기간은 티켓 뒷면에 인쇄되어 있습니다.'] },
                { cid: 3, text: ['이 티켓은 ‘보통, 구간 급행, 준급행, 급행, 쾌속, 특급, 쾌속 특급’ 편을 승차할 수 있습니다. 프리미엄카 (동일 열차의 지정석 차량)에는 승차하실 수 없습니다.'] },
                { cid: 4, text: ['프리미엄카에 승차하기 위해서는 별도 요금이 필요합니다. '] },
                ]
            }
          ]
        },
        // 
        {
          id: 21,
          img: require('@/assets/images/hfkansai/en/CarThemePavilion_en.png'),
          title: ['일본 이니셜 D 스포츠카 렌털관 쿠폰(5500엔)','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒551-0031 오사카부 오사카시 다이쇼구 이즈오 4-12-21'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://mrhiro-jdm.com/']},
                { cid: 2, text: ['예약'],noColor:true},
                { cid: 3, text: ['https://mrhiro-jdm.com/rental']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['포함내용 :','<br>① 일본 이니셜 D 스포츠카 렌털 2,000엔 할인 ','<br>② 레이싱 시뮬레이터 체험 30분 이상 2,000엔 할인 ','<br>③ 드리프트 카트 체험 20분 이상 1,500엔 할인'] },
                { cid: 2, text: ['여러 사람이 한 장의 티켓으로 각각 다른 체험을 이용할 수 있습니다. 예를 들어, 성인이 레이싱 시뮬레이터를 체험할 때, 어린이는 드리프트 카트를 체험할 수 있습니다.'] },
                { cid: 3, text: ['체험은 당일에 한합니다.'] },
                { cid: 4, text: ['이니셜 D 스포츠카 렌털은 사전에 공식 사이트에서 예약하시는 것을 추천드립니다.'] },
                { cid: 5, text: ['만원일 시 이용할 수 없는 경우도 있으므로 미리 양해 부탁드립니다.'] },
                { cid: 6, text: ['스포츠카 렌탈 체험은 면허증이 필요합니다. 사전에 홈페이지에서 확인해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 22,
          img: require('@/assets/images/hfkansai/en/terminal_en.png'),
          title: ['오사카부 레스토랑 플로어 "16 Gourmet" 식사권 1,300엔','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '(우)530-0001 오사카부 오사카시 기타구 우메다 3-1-3 JR오사카역 5층 시공광장 "BAR DEL SOLE"','<span style="color:red;">※"BAR DEL SOLE" 7/18(화) ~ 21(금)까지 휴무 (식사권 교환 불가)</span>'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://osakastationcity.com/16gourmet//']},
                { cid: 2, text: ['https://osakastationcity.com/pdf/ichiroku_inbound.pdf']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['대상 매장에서 이용할 수 있는 식사권 1,000엔분 1장, 300엔분 1장, 총2장으로 교환해 드립니다.'] },
                { cid: 2, text: [
                  '식사권은 다음 매장에서 사용할 수 있습니다.',
                  '<br>・SOUTH GATE BUILDING 16층 16 Gourmet「미야자키 샤부샤부 키리미네(宮崎しゃぶしゃぶ霧峰)」「아 란(あ・らん)」「오사카 치쿠요테이(大阪竹葉亭)」「우오사(うを佐)」「긴자 하게텐(銀座ハゲ天)」「요멘야 고에몬(洋麺屋五右衛門)」「살롱 타마고토와타시(サロン卵と私)」「SAINT MARC(サンマルク)」「카페 에이코쿠야(カフェ 英國屋)」「니쿠노타쿠미 나가타야(肉の匠 永田屋)」「겐(弦)」「핫카쿠안(八かく庵)」「PIER 30 GRILL」「중국요리 로도스(中国料理 ロドス)」',
                  '<br>・SOUTH GATE BUILDING 1층「Cafe de Clever」',
                  '<br>・SOUTH GATE BUILDING 지하1층「산마르코」',
                  '<br>・시공광장(오사카역 5층) 「BAR DEL SOLE」'
                  ] },
                { cid: 3, text: ['식사권은 한 번의 계산에서 여러장 사용 가능합니다.'] },
                { cid: 4, text: ['식사권은 현금으로 교환 또는 거스름돈을 제공하지 않습니다.'] },
                { cid: 5, text: ['식사권은 사용하신 매장에서 수거합니다.'] },
                { cid: 6, text: ['식사권은 다이마루 우메다점, 호텔 그랑비아 오사카, 루쿠아 오사카, EKI MARCHE 오사카, 오사카 스테이션시티 시네마 등에서는 사용하실 수 없습니다.'] },
                { cid: 7, text: ['이용매장은 변경될 수 있습니다.'] },
                { cid: 8, text: ['영업시간은 예고없이 변경될 수 있습니다. 임시휴업, 만석 등으로 이용하지 못한 경우, 환불되지 않습니다.'] },
                { cid: 9, text: ['식사권은 교환 후 취소 및 환불 불가합니다.'] },
                { cid: 10, text: ['QR코드를 제시하시면 이용할 수 없습니다. 반드시 식사권으로 교환한 뒤 각 매장을 이용해 주세요.'] },
                { cid: 11, text: ['대상 매장 외에는 사용하실 수 없습니다.'] },
                ]
            }
          ]
        },
        {
          id: 23,
          img: require('@/assets/images/hfkansai/en/meitian_en.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/ezYPmNdS_no',
          title: ['우메다 스카이빌딩 공중정원 전망대 입장권 ','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒531-6039 오사카시 기타구 오요도나카 1-1-88 우메다 스카이빌딩 (39층 티켓 카운터)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.skybldg.co.jp/ko/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['나니와 요도가와 불꽃축제 개최일(2023/8/5), 연말연시 등의 특별영업일에는 사용불가.'] },
                { cid: 2, text: ['휠체어를 이용하시는 고객님은 타워 이스트 1층에서 고층용 엘리베이터를 이용해 주세요.'] },
                { cid: 3, text: ['악천후로 인해 옥상에 입장하지 못하는 경우가 있으므로 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 24,
          img: require('@/assets/images/hfkansai/en/CHOICE_en.png'),
          title: ['BAR CHOICE 1,000엔 쿠폰','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒542-0083 주오구 히가시신사이바시 1-8-24  요네야마빌딩 1F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://choice-cola.com/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000엔 쿠폰을 이용할 수 있습니다.'] },
                { cid: 2, text: ['시간을 사전에 공식사이트에서 확인해 주세요.'] },
                { cid: 3, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 4, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다. '] },
                ]
            }
          ]
        },
        {
          id: 25,
          img: require('@/assets/images/hfkansai/en/Wonder_en.png'),
          title: ['Dotonbori Grill & Bar Wonder 1,000엔 쿠폰','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒542-0071 오사카시 주오구 도톤보리 1-1-6 강변 B1'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://wonderpub.jp/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000엔 쿠폰을 이용할 수 있습니다.'] },
                { cid: 2, text: ['시간을 사전에 공식사이트에서 확인해 주세요.'] },
                { cid: 3, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 4, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다. '] },
                { cid: 5, text: ['주류도 판매합니다. 만20세이상 구입가능.'] },
                ]
            }
          ]
        },
        {
          id: 26,
          img: require('@/assets/images/hfkansai/en/chisozanmai_en.png'),
          title: ['다이마루 마쓰자카야 백화점 치소장마이 다이마루 우메다점 1000엔 쿠폰','<span>(2023/6/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒530-8202 오사카시 기타구 우메다 3-1-1 다이마루 오사카・우메다점14F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.daimaru.co.jp.k.md.hp.transer.com/umedamise/restaurant/chisozanmai.html']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000엔 쿠폰을 이용할 수 있습니다.(단, 1인당 5000엔 이상 이용하는 경우에만 사용 가능합니다.)'] },
                { cid: 2, text: ['시간을 사전에 공식사이트에서 확인해 주세요.'] },
                { cid: 3, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 4, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다. '] },
                ]
            }
          ]
        },
        {
          id: 28,
          img: require('@/assets/images/hfkansai/en/LUCUAosaka_en.jpg'),
          title: ["오사카역 직결 쇼핑 센터 'LUCUA osaka' 관내 공통 상품권 1,000엔",'<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                'LUCUA 1100 2층 인포메이션 카운터에','<span style="color:red;">※접수는 2023년12월31일까지쿠</span>'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.lucua.jp/ko/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['포함내용 :1000엔분의 공통쿠폰 1장'] },
                { cid: 2, text: ['상품권의 유효기간은 2023년8월31일에 교환한 경우, 2023년8월31일까지 유효합니다. 2023년9월1일이후 교환한 경우, 2024년1월31일까지 유효합니다.'] },
                { cid: 3, text: ['이 쿠폰은 현금으로 교환 또는 다른 상품권, 우표, 엽서 등의 상품구입에는 사용하실 수 없습니다.'] },
                { cid: 4, text: ['거스름돈은 제공되지 않습니다.'] },
                { cid: 5, text: ['LUCUA 내 일부 매장에서는 사용하실 수 없습니다. (이용불가매장 : LUCUA 5층 ""C-pla(シープラ)"", LUCUA 9층 ""후지모토 안과"", LUCUA1100 B1층 ""맥도날드"")더불어 게재되어 있는 매장사진은 2023년3월시점의 사진으로, 변경될 가능성이 있습니다.'] },
                { cid: 6, text: ['영업시간은 예고없이 변경될 수 있습니다. 임시휴업 및 만석 등으로 이용하지 못한 경우라도 환불은 불가합니다.'] },
                ]
            }
          ]
        },
        {
          id: 29,
          img: require('@/assets/images/hfkansai/en/EKI_MARCHE_en.jpg'),
          title: ['오사카역 구내 쇼핑센터 "EKI MARCHE 오사카" 관내 공통쿠폰 1000엔','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                'EKI MARCHE 오사카 내 인포메이션 카운터에',
                '<span style="color:red;">※접수는 2023년12월31일까지쿠</span>',
                '<span style="color:red;">※E티켓의 쿠폰실권교환은 구매일부터 90일간 유효합니다.</span>',
                '<span style="color:red;">※시설휴관일 <5/9(화), 9/5(화)>에는 이용 및 교환하실 수 없습니다.</span>'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://osaka.ekimaru.com/kr']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['포함내용 :500엔분의 공통쿠폰 2장'] },
                { cid: 2, text: ['쿠폰의 유효기간은 교환일로부터 다음달 말까지 입니다. (예: 3/15에 교환한 경우, 유효기간은 4/30까지 입니다.)'] },
                { cid: 3, text: ['해당 쿠폰은 한 번의 계산에서 여러장 사용 가능합니다.'] },
                { cid: 4, text: ['해당 쿠폰은 현금으로 교환 또는 거스름돈을 제공하지 않습니다.'] },
                { cid: 5, text: ['영업시간은 예고없이 변경될 수 있습니다. 임시휴업 및 만석 등으로 이용하지 못한 경우에도 환불되지 않습니다.'] },
                { cid: 6, text: ['교환 후에는 취소 및 변경 불가합니다.'] },
                { cid: 7, text: ['QR코드를 제시하시면 이용하실 수 없습니다. 반드시 쿠폰으로 교환하여 매장에서 이용해 주세요.'] },
                { cid: 8, text: ['대상 매장 외에는 사용할 수 없습니다.','<br>(EKI MARCHE 오사카 내 쿠폰 대상외 매장 : JTB, 은행ATM, DreamShop)'] },
                { cid: 9, text: ['500명 한정으로, 소진시 종료됩니다. '] },
                ]
            }
          ]
        },
        {
          id: 31,
          img: require('@/assets/images/hfkansai/en/Yamachan_en.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/dbJ6ER5g6GM',
          title: ['오사카 우메다 다코야키 마켓 야마짱 HEP NAVIO점 1,000엔 쿠폰 ','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '530-0017 오사카부 오사카시 기타구 가쿠다초 5-15 HEP FIVE 1층'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용하실 수 있습니다. '] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. '] },
                { cid: 3, text: ['요금 차액이 발생할 경우, 현장에서 추가 지불하셔야 합니다. '] },
                { cid: 4, text: ['만석으로 인해 이용하지 못할 수도 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 32,
          img: require('@/assets/images/hfkansai/en/HEP_NAVIO_en.jpg'),
          title: ['오사카 우메다 다코야키 마켓 다코야키 주하치방 HEP NAVIO점 1,000엔 쿠폰 ','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '530-0017 오사카부 오사카시 기타구 가쿠다초 7-10 HEP FIVE 1층'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용하실 수 있습니다. '] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. '] },
                { cid: 3, text: ['요금 차액이 발생할 경우, 현장에서 추가 지불하셔야 합니다. '] },
                { cid: 4, text: ['만석으로 인해 이용하지 못할 수도 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 33,
          img: require('@/assets/images/hfkansai/en/Yashiki_en.jpg'),
          title: ['오사카 우메다 다코야키 마켓 고가류 HEP NAVIO점 1,000엔 쿠폰 ','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '530-0017 오사카부 오사카시 기타구 가쿠다초 7-10 HEP FIVE 1층'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용하실 수 있습니다. '] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. '] },
                { cid: 3, text: ['요금 차액이 발생할 경우, 현장에서 추가 지불하셔야 합니다. '] },
                { cid: 4, text: ['만석으로 인해 이용하지 못할 수도 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 34,
          img: require('@/assets/images/hfkansai/en/kukuru_HEP_FIVE_en.jpg'),
          title: ['오사카 우메다 다코야키 마켓 쿠쿠루 HEP FIVE점 1,000엔 쿠폰 ','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '530-0017 오사카부 오사카시 기타구 가쿠다초 5-15 HEP FIVE 1층'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용하실 수 있습니다. '] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. '] },
                { cid: 3, text: ['요금 차액이 발생할 경우, 현장에서 추가 지불하셔야 합니다. '] },
                { cid: 4, text: ['만석으로 인해 이용하지 못할 수도 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 35,
          img: require('@/assets/images/hfkansai/en/HEP_NAVIO_Umeda_en.jpg'),
          title: ['오사카 우메다 다코야키 마켓 아이즈야 우메다 HEP NAVIO점 1,000엔 쿠폰 ','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '530-0017 오사카부 오사카시 기타구 가쿠다초 7-10 HEP FIVE 1층'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용하실 수 있습니다. '] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. '] },
                { cid: 3, text: ['요금 차액이 발생할 경우, 현장에서 추가 지불하셔야 합니다. '] },
                { cid: 4, text: ['만석으로 인해 이용하지 못할 수도 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 36,
          img: require('@/assets/images/hfkansai/en/bistecca_en.png'),
          title: ['bistecca IL FORNO 1000엔 쿠폰','<span>(2023/8/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒550-0015 오사카부 오사카시 니시구 미나미호리에 1-5-17 캐널 테라스 호리에 서관 1F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://bistecca-ilforno.jp/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용하실 수 있습니다. '] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. '] },
                { cid: 3, text: ['요금 차액이 발생할 경우, 현장에서 추가 지불하셔야 합니다. '] },
                { cid: 4, text: ['만석으로 인해 이용하지 못할 수도 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 37,
          img: require('@/assets/images/hfkansai/en/MIO_EN.jpg'),
          title: ['덴노지MIO 관내 공통쿠폰 1,500엔','<span>(2023/9/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오사카시 덴노지구 히덴인쵸 10-39 (543-0055) "덴노지MIO 본관" 2층 인포메이션 카운터','※접수는 2023년12월30일까지쿠'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.tennoji-mio.co.jp/lang/ko/access/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['텐노지 미오(본관 및 플라자 빌딩) 내 매장에서만 유효합니다.'] },
                { cid: 2, text: ['일부 점포에서는 사용할 수 없습니다. 쿠폰 사용 시 각 점포의 직원에게 문의하시기 바랍니다.'] },
                { cid: 3, text: ['쿠폰과의 차액은 현금으로 결제해 주세요. 잔돈은 제공되지 않습니다.'] },
                { cid: 4, text: ['쿠폰은 환불되지 않으며 현금으로 교환할 수 없습니다.'] },
                { cid: 5, text: ['당사는 쿠폰의 화재, 도난, 분실 또는 파손에 대해 책임을 지지 않습니다.'] },
                { cid: 6, text: ['일부 점포의 영업시간은 이와 다릅니다. 영업시간은 예고 없이 변경될 수 있습니다.'] },
                { cid: 7, text: ['포함내용 :500엔분의 공통쿠폰 3장'] },
                ]
            }
          ]
        },
        {
          id: 43,
          img: require('@/assets/images/hfkansai/en/EDION_EN.jpg'),
          title: ['EDION 난바 관광 만끽 패키지 1,000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오사카시 주오구 난바 3-2-18 에디온 난바 본점 8층'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://namba.edion.com/en/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용하실 수 있습니다.'] },
                { cid: 2, text: [
                  '만끽 패키지 포함 내용 : ',
                  '<br>・닌자 탈출 게임 체험 (1,000엔 상당) ',
                  '<br>・짐 보관 1일 무료 (800엔 상당) ',
                  '<br>・파우더룸 1시간 이용 (300엔 상당) ※최신 가전제품 및 화장품을 사용하실 수 있습니다. ',
                  '<br>・충전 30분 이용 (200엔 상당) ※아이폰, 안드로이드 모두 이용 가능합니다. '
                ] },
                { cid: 3, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요. '] },
                { cid: 4, text: ['요금 차액이 발생할 경우, 현장에서 결제해 주세요. '] },
                { cid: 5, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] }
                ]
            }
          ]
        },
        {
          id: 44,
          img: require('@/assets/images/hfkansai/en/rentcar_en.png'),
          title: ['에키 렌터카 2,000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '(1) 에키 렌터카 신오사카 영업소',
                '5-chōme-16-1 Nishinakajima, Yodogawa Ward, Osaka (JR신오사카역 남쪽 출구 1층 오른쪽, 약 50m)',
                '(2) 에키 렌터카 교토 영업소',
                '940 Higashishiokōjichō, Shimogyo Ward, Kyoto (JR교토역 중앙 출구를 나와서 왼쪽 역 빌딩 주차장 1층)',
                '(3) 에키 렌터카 신코베 영업소',
                '1-chōme-3-1 Kanōchō, Chuo Ward, Kobe, Hyogo (JR신코베역 1층 중앙 통로)',
                '(4) 에키 렌터카 히메지 영업소',
                '125 Minamiekimaechō, Himeji, Hyogo (JR히메지역 남쪽 출구 왼쪽, 에키마에 주차장 내)',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['(1) 에키 렌터카 신오사카 영업소 '],noColor:true},
                { cid: 2, text: ['https://www.ekiren.com/eigyosyo?eigyou=270200 ']},
                { cid: 3, text: ['(2) 에키 렌터카 교토 영업소 '],noColor:true},
                { cid: 4, text: ['https://www.ekiren.com/eigyosyo?eigyou=260400 ']},
                { cid: 5, text: ['(3) 에키 렌터카 신코베 영업소 '],noColor:true},
                { cid: 6, text: ['https://www.ekiren.com/eigyosyo?eigyou=290200 ']},
                { cid: 7, text: ['(4) 에키 렌터카 히메지 영업소 '],noColor:true},
                { cid: 8, text: ['https://www.ekiren.com/eigyosyo?eigyou=290800']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['2,000엔 쿠폰을 사용하실 수 있습니다. '] },
                { cid: 2, text: ['당일에 한하여 이용 가능하며, 예약 불가합니다. '] },
                { cid: 3, text: ['사용 방법 : 대상 점포에서 렌터카를 신청하실 때, 간사이 조이 패스의 QR코드, 국제운전면허증(영문 운전면허증 불가하므로, 반드시 국제운전면허증 지참), 여권(일본 국적 이외의 분)을 제시해 주세요. '] },
                { cid: 4, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요. '] },
                { cid: 5, text: ['요금 차액이 발생할 경우, 현장에서 결제해 주세요. '] },
                { cid: 6, text: ['차량 종류는 지정할 수 없습니다. '] },
                { cid: 7, text: ['만원으로 인해 이용할 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                { cid: 8, text: ['반드시 일본에서 유효한 "국제운전면허증"을 지참해 주세요. (영문 운전면허증 불가)'] },
                { cid: 9, text: ['차량 반납 시, 기름은 가득 채운 후 반납해 주세요. 반납 시에 별도 정산도 가능하지만, 일반 주유소(시장 가격)보다 비싸게 책정되므로 미리 기름을 가득 채운 후에 반납하시는 것을 추천드립니다. (반납 시 정산하는 경우 주행 거리로 계산됩니다)'] },
                { cid: 10, text: ['해당 상품을 이용하시는 경우 일본 국내법 및 렌터카 대여 약관에 준합니다.'] },
                ]
            }
          ]
        },
        {
          id: 45,
          img: require('@/assets/images/hfkansai/en/mingShi_en.png'),
          title: ['아카시 주변 스시집 2,000엔 쿠폰 "기예의 일품"'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['1-chōme-1-23 Ōakashichō, Akashi, Hyogo (JR아카시역 아카시 인포메이션 센터)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.yokoso-akashi.jp/coupon.html']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['포함 내용 : 쿠폰 금액(2,000엔)에 상당하는 아카시 주변 스시집 메뉴 (1인분)'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 3, text: ['식자재 입고 상황 및 계절에 따라 메뉴의 일부가 변경될 수 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 38,
          img: require('@/assets/images/hfkansai/en/ADVENTURE_WORLD_EN.jpg'),
          title: ['어드벤처 월드 1일 입장권 1000엔 할인 쿠폰','<span>(2023/9/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒649-2201 와카야마현 니시무로군 시라하마초 가타타 2399 (티켓 판매소)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.aws-s.com/en/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000엔 할인 쿠폰을 사용하실 수 있습니다. (입장권 구입 시에만 사용 가능)'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. '] },
                { cid: 3, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfkansai/en/グループ 255.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/N-MxbCGVfIM',
          title: ['교토철도박물관','<span>(2023/8/18~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['교토부 교토시 시모교구 간키지마치 (입장 게이트)'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://www.kyotorailwaymuseum.jp/kr/'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['당일에 한해 재입장 가능하므로, 재입장 시 직원에게', '입장권을 제시해 주세요.'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요.'] },
              ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfkansai/en/グループ 225.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/3vEpVmem5dU',
          title: ['교토 아라시야마 미식투어쿠폰+란덴 1일승차권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['교토부 교토시 우쿄구 사가텐류지 조로쵸 20-2', '(아라시야마역 안내소)'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://www.keifuku.co.jp/cms/wp-content/uploads/cc0d12d1bb04c0b9865d0595a5d770a9-768x975.jpg'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['전철 시간표는 공식사이트에서 확인해 주세요.'] },
                { cid: 2, text: ['미식쿠폰의 대상 점포는 공식사이트에서 확인해 주세요.'] },
                { cid: 3, text: ['잔여 쿠폰은 현금화할 수 없으므로 양해 부탁드립니다.'] },
              ]
            }
          ]
        },
        // 
        {
          id: 10,
          img: require('@/assets/images/hfkansai/sake_EN.jpg'),
          title: ['Japanese SAKE Festival in KYOTO ', "'Sake Spring' 2023 여름축제 초이노미 티켓"],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['606-8343 교토시 사쿄구 오카자키세이쇼지초 9-1 ','(교토시 간교칸 미야코 메세)'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://www.sakesp.com/2023summer'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['이벤트 개최기간: 2023/7/8 ~ 2023/7/9'] },
                { cid: 2, text: ['이벤트에는 모두 시음이 포함되어 있습니다. 만20세 미만은 이용할 수 없습니다.'] },
                { cid: 3, text: ['한번 퇴장하시면 재입장하실 수 없으므로 주의해 주세요.'] },
                { cid: 4, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요.'] },
              ]
            }
          ]
        },
        {
          id: 39,
          img: require('@/assets/images/hfkansai/en/TOEI_EN.jpg'),
          title: ['도에이 우즈마사 영화촌 입장권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '교토부 교토시 우쿄구 우즈마사 히가시 하치오카초 10'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://global.toei-eigamura.com/ko/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 2, text: ['각 어트랙션에는 이용 기준이 있으므로 사전에 공식 홈페이지에서 확인해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 40,
          img: require('@/assets/images/hfkansai/en/pph_EN.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/nvht8Y_bbYk',
          title: ['비와코 오쓰 프린스호텔 관내 공통 상품권 1,500엔'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '시가현 오쓰시 니오노하마 4-7-7'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.princehotels.com/otsu/ko/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['관내 공통 상품권의 유효기간은 발행일로부터 일주일입니다. 유효기간이 지난 상품권은 사용하실 수 없습니다.'] },
                { cid: 2, text: ['해당 상품권을 숙박비로 사용하실 수 없습니다.'] },
                { cid: 3, text: ['요금 차액이 발생할 경우, 현장에서 결제해 주세요. 또한, 이용하고 남은 잔돈은 드리지 않습니다.'] },
                { cid: 4, text: ['영업시간은 예고 없이 변경될 수 있으며, 임시 휴업 및 만석 등 여러 사정으로 인해 이용할 수 없는 경우에도 환불은 불가합니다.'] },
                ]
            }
          ]
        },
        {
          id: 41,
          img: require('@/assets/images/hfkansai/en/PASSPORT_EN.jpg'),
          title: ['나가하마 오데카케 패스포트 (2일권)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '나가하마시 기타후나초 1-5 (JR 나가하마역 구내)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://kitabiwako.jp/kr/travel_guide']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['해당 티켓은 이용 개시일을 포함하여 2일간 유효합니다. (※48시간이 아니므로 주의해 주세요)'] },
                { cid: 2, text: ['한 시설당 1번만 이용 가능합니다. (같은 시설을 여러 번 이용 불가)'] },
                { cid: 3, text: ['사전에 시설 및 점포의 영업시간과 휴무일을 확인하신 후 이용해 주세요.'] },
                { cid: 4, text: ['각 시설의 특별전에 따라 추가 요금이 발생할 수 있습니다.'] },
                ]
            }
          ]
        },
        {
          id: 42,
          img: require('@/assets/images/hfkansai/en/yxt_EN.jpg'),
          title: ['잇신도 1,000엔 할인 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '나라현 나라시 가미산조초'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://issindo-nara.jp/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용하실 수 있습니다.'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 3, text: ['요금 차액이 발생할 경우, 현장에서 결제해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 30,
          img: require('@/assets/images/hfkansai/en/Yoshitaya_en.jpg'),
          title: ['요시타카야 거북이표 아름다운 피부 비누 교환권','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒651-1401 효고현 고베시 기타구 아리마초 259'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.yoshitakaya.com/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['사진은 이미지입니다. 사진과 실제 상품이 일부 다른 경우가 있습니다.'] },
                { cid: 2, text: ['시간을 사전에 공식사이트에서 확인해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 27,
          img: require('@/assets/images/hfkansai/en/carbonated_en.jpg'),
          title: ['원조 미쓰모리 혼포 탄산 센베이의 30개 들이','<span>(2023/6/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒651-1401 효고현 고베시 기타구 아리마초 290-1'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['http://tansan.co.jp/']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['가맹점이 혼잡한 경우가 있습니다. 일반 손님과 동일하게 대기하신 후 이용해 주세요. 해당 티켓은 우선권이 아닙니다.'] },
                { cid: 2, text: ['사진은 이미지입니다. 사진과 실제 상품이 일부 다른 경우가 있습니다.'] },
                { cid: 3, text: ['시간을 사전에 공식사이트에서 확인해 주세요.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 11,
          img: require('@/assets/images/hfkansai/en/グループ 226.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/q101QK5r8Sc',
          title: ['고베 누노비키 허브가든&로프웨이'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['효고현 고베시 주오구 기타노초 1-4-3'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://www.kobeherb.com/kr/'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['야간 영업 시(17:00 이후)에는 전망 지역만 입장할 수 있습니다.'] },
                { cid: 2, text: ['연중무휴 (단, 겨울철에 1개월 정도 연례 점검을 위하여 운휴 ', '및 휴업될 수 있습니다.)'] },
                { cid: 3, text: ['천재지변으로 인해 운휴 및 휴업될 수 있습니다.'] },
              ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfkansai/en/グループ 227.png'),
          title: ['고베 콘체르토 1000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['고베시 주오구 히가시 가와사키초 1-6-1 고베', '하버랜드 umie 1F'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://thekobecruise.com/concerto/'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['운행스케줄은 공식사이트에서 확인해 주세요.'] },
                { cid: 2, text: ['만석인 경우에는 승선이 거절될 수 있습니다.'] },
                { cid: 3, text: ['요금차액은 현장에서 결제가 필요합니다.'] },
              ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfkansai/en/グループ 228.png'),
          title: ['모자이크 대관람차'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['고베시 주오구 히가시 가와사키초 1-6-1 고베 하버랜드 umie (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://umie.jp/features/mosaickanransya'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: false, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['천재지변 및 정비 등으로 인해 휴업될 수 있습니다.'] },
              ]
            }
          ]
        },
        {
          id: 14,
          img: require('@/assets/images/hfkansai/en/グループ 232.png'),
          title: ['칸논야 쿠폰 1000엔권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['고베시 주오구 히가시 가와사키초 1-6-1 고베 하버랜드 umie 1F (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/76'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000엔 쿠폰을 이용할 수 있습니다.'] },
                { cid: 2, text: ['요금차액은 현장에서 결제가 필요합니다.'] },
                { cid: 3, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다. '] },
              ]
            }
          ]
        },
        {
          id: 15,
          img: require('@/assets/images/hfkansai/en/グループ 246.png'),
          title: ['톤톤 돈가츠 1000엔쿠폰/세트메뉴'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['고베시 주오구 히가시 가와사키초 1-6-1 고베 하버랜드 umie 2F (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/93'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000엔 쿠폰/세트메뉴를 이용할 수 있습니다.'] },
                { cid: 2, text: ['요금차액은 현장에서 결제가 필요합니다.'] },
                { cid: 3, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다. '] }
              ]
            }
          ]
        },
        {
          id: 16,
          img: require('@/assets/images/hfkansai/en/グループ 233.png'),
          title: ['Frantz 디저트 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['고베시 주오구 히가시 가와사키초 1-6-1 고베 하버랜드 umie 2F (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/98'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: false, //是否启用ul的数字前缀 //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['3종류의 디저트 중 1개를 선택해 주세요.'] },
              ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFKansai&l=kr')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-kansai-1-week-free-pass/kr', '_self')
  }
}
</script>
<style lang="scss" scoped>
.xiao_btn_img{
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-top: 16px;
  a{
    height: 32px;
    width: 32%;
    // flex: 1;
    // text-align: center;
  }
  img{
    // flex: 1;
    // width: auto;
    width: 90%;
    max-width: 144px;
    height: auto;
    // height: 100%;
  }
}
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
.dltAbq:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>